<template>
  <div>
    <b-card :title="currentStatus === 'CREATED' ? `Demandes d'aide en attente` : `Demandes d'aide clôturés`">
      <div class="d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1 text-uppercase">Etat</label>
            <b-form-select
              v-model="currentStatus"
              :options="statusOptions"
            />
          </div>
        </b-form-group>
      </div>
      <b-table
        hover
        responsive
        show-empty
        empty-text="Aucune aide en attente"
        :items="supports"
        :fields="fields"
        :busy="loaded"
      >
        <template #table-busy>
          <div class="d-flex justify-content-center mb-3">
            <b-spinner
              variant="primary"
              label="Loading..."
            />
          </div>
        </template>
        <template v-slot:cell(date)="data">
          {{ data.item.created_at | moment("DD/MM/YY HH:mm") }}
        </template>
        <template v-slot:cell(index)="data">
          {{ data.item.ticket }}
        </template>
        <template v-slot:cell(action)="data">
          <span class="whitespace-no-wrap">
            <feather-icon
              icon="EyeIcon"
              svg-classes="w-5 h-5 hover:text-success stroke-current"
              class="text-success"
              @click="openModal(data.item)"
            />
          </span>
        </template>
      </b-table>
      <div class="mt-2">
        <div class="float-left">
          <b-form-select
            v-model="perPage"
            :options="pageOptions"
          />
        </div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="perPage"
          first-number
          last-number
          align="right"
        />
      </div>
    </b-card>
    <b-modal
      v-if="showDetailsModal"
      v-model="showDetailsModal"
      centered
      hide-footer
      size="lg"
      title="Détails"
      :no-close-on-backdrop="process"
    >
      <div>
        <b-tabs
          justified
          content-class="mt-3"
        >
          <b-tab
            title="Support"
            title-item-class="text-uppercase"
            active
          >
            <b-row>
              <b-col>
                <b-table-simple
                  responsive
                  hover
                >
                  <b-tr>
                    <th>
                      Ticket
                    </th>
                    <b-td>
                      {{ currentSupport.ticket }}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <th>
                      Nom
                    </th>
                    <b-td>
                      {{ currentSupport.name }}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Email
                    </b-td>
                    <b-td>
                      {{ currentSupport.email }}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Titre
                    </b-td>
                    <b-td>
                      {{ currentSupport.title }}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Message
                    </b-td>
                    <b-td>
                      {{ currentSupport.message }}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Status
                    </b-td>
                    <b-td>
                      {{ currentSupport.state }}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Création
                    </b-td>
                    <b-td>
                      {{ currentSupport.created_at | moment("DD/MM/YY HH:mm") }}
                    </b-td>
                  </b-tr>
                </b-table-simple>
              </b-col>
              <b-col v-if="currentSupport.file_name">
                <b-img
                  class="mb-0 img-fluid w-100"
                  :src="currentSupport.file_name"
                />
              </b-col>
            </b-row>
          </b-tab>

          <b-tab
            title="Réponse"
            title-item-class="text-uppercase"
          >
            <b-row>
              <b-col sm="12">
                <quill-editor
                  ref="myEditor"
                  v-model="$v.content.$model"
                  :options="snowOption"
                  :class="{ 'is-invalid': $v.content.$error }"
                />
                <div
                  v-if="$v.content.$error"
                  class="invalid-feedback"
                >
                  <span
                    v-if="!$v.content.required"
                  >{{ $t('errors.required') }}</span>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12">
                <div
                  v-if="process"
                  class="d-flex justify-content-center mt-1"
                >
                  <b-spinner
                    variant="primary"
                    label="Loading..."
                  />
                </div>
                <div class="mt-3">
                  <b-button
                    variant="secondary"
                    class="float-left"
                    :disabled="process"
                    @click="resetForm"
                  >
                    {{ $t("form.refresh") }}
                  </b-button>
                  <b-button
                    type="submit"
                    variant="outline-primary"
                    class="ml-2 float-right"
                    :disabled="process"
                    @click="tryToResponse"
                  >
                    Réponse
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  ['clean'],
]

export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      supports: [],
      loaded: false,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, 20],
      totalItems: 0,
      currentStatus: 'CREATED',
      statusOptions: [{ value: 'CREATED', text: 'En attente' }, { value: 'CLOSED', text: 'Fermé' }],
      fields: [
        { key: 'ticket', label: 'Ticket' },
        { key: 'name', label: 'Demandeur' },
        { key: 'title', label: 'Titre' },
        { key: 'message', label: 'Objet' },
        { key: 'action', label: 'Détails', class: 'text-center' },
      ],
      currentSupport: null,
      showDetailsModal: false,
      content: '',
      contentHtml: null,
      snowOption: {
        theme: 'snow',
        placeholder: 'Réponse',
        modules: {
          toolbar: toolbarOptions,
        },
      },
    }
  },
  validations: {
    content: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      success: 'replySupportSuccess',
      error: 'replySupportError',
      process: 'replySupportProcess',
    }),
  },
  watch: {
    content(val) {
      if (val && this.showDetailsModal) {
        this.contentHtml = this.$refs.myEditor.quill.root.innerHTML
      }
    },
    success(val) {
      if (val) {
        this.closeModal()
        this.resetForm()
        this.getSupportByState()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('support.title'),
            text: this.$t('support.success'),
            icon: 'XCircleIcon',
            variant: 'success',
          },
        })
      }
    },
    error(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('support.title'),
            text: this.$t('support.error'),
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
    $route: {
      immediate: true,
      handler() {
        this.getSupportByState()
      },
    },
    currentPage(val) {
      if (val) {
        this.getSupportByState()
      }
    },
    currentStatus(val) {
      if (val) {
        this.getSupportByState()
      }
    },
    perPage(val) {
      if (val) {
        this.getSupportByState()
      }
    },
  },
  methods: {
    ...mapActions(['replySupport']),
    resetForm() {
      this.content = null
      this.contentHtml = null
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    tryToResponse() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        return
      }
      this.replySupport({
        id: this.currentSupport.id,
        response: this.contentHtml,
      })
    },
    openModal(item) {
      this.currentSupport = item
      this.showDetailsModal = true
    },
    closeModal() {
      this.currentSupport = null
      this.showDetailsModal = false
    },
    async getSupportByState() {
      this.loaded = true
      this.supports = await this.$http
        .get(`/support-all-by-state/${this.currentStatus}/${this.perPage}?page=${this.currentPage}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.currentToken}`,
          },
        })
        .then(res => {
          const { data } = res
          this.loaded = false
          this.totalItems = data.meta.total
          return data.data
        })
        .catch(() => {
          this.loaded = false
          return []
        })
    },
  },
}
</script>
<style lang="scss" scoped>
 .cursor {
   cursor: pointer
 }
@import '@core/scss/vue/libs/quill.scss';
</style>
